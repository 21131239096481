import payload_plugin_qLmFnukI99 from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ticketco-tickets/ticketco-tickets/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/ticketco-tickets/ticketco-tickets/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _00_configService_0mQtHGYFfE from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/00.configService.js";
import _00_queueit_CI9Wjx33hK from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/00.queueit.js";
import _00_utilities_iS7VSXsXDA from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/00.utilities.js";
import _01_authService_sb5G5u0J9e from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.authService.js";
import _01_countriesAndStatesService_MEket6rhha from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.countriesAndStatesService.js";
import _01_currencyService_Ow7hVMux0z from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.currencyService.js";
import _01_fontawesome_uhqujv3mZN from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.fontawesome.js";
import _01_i18n_tuYmYnlJNX from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.i18n.js";
import _01_mitt_client_s8rHTiE59C from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.mitt.client.js";
import _01_mouseflow_NfZoj7ejd0 from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.mouseflow.js";
import _01_persistentStores_ez28M9D1ai from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.persistentStores.js";
import _01_postalCodeService_dNQsAJEQmP from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.postalCodeService.js";
import _01_sentry_t6z2suMqRh from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/01.sentry.js";
import _02_apiHelper_O3hAeD9v4h from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/02.apiHelper.js";
import _02_cartTimeoutService_8PAaLBCLdy from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/02.cartTimeoutService.js";
import _02_cookiesConsent_JxjydqOk7z from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/02.cookiesConsent.js";
import _02_productsApiService_ZvQL0cqZbd from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/02.productsApiService.js";
import _03_cartApiService_zNjNzc6y5U from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/03.cartApiService.js";
import _03_productsService_7BAsp43aGL from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/03.productsService.js";
import _04_cartService_WV5Z78ltSP from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/04.cartService.js";
import _05_seatSelector_KIK5eMKCiR from "/home/runner/work/ticketco-tickets/ticketco-tickets/plugins/05.seatSelector.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _00_configService_0mQtHGYFfE,
  _00_queueit_CI9Wjx33hK,
  _00_utilities_iS7VSXsXDA,
  _01_authService_sb5G5u0J9e,
  _01_countriesAndStatesService_MEket6rhha,
  _01_currencyService_Ow7hVMux0z,
  _01_fontawesome_uhqujv3mZN,
  _01_i18n_tuYmYnlJNX,
  _01_mitt_client_s8rHTiE59C,
  _01_mouseflow_NfZoj7ejd0,
  _01_persistentStores_ez28M9D1ai,
  _01_postalCodeService_dNQsAJEQmP,
  _01_sentry_t6z2suMqRh,
  _02_apiHelper_O3hAeD9v4h,
  _02_cartTimeoutService_8PAaLBCLdy,
  _02_cookiesConsent_JxjydqOk7z,
  _02_productsApiService_ZvQL0cqZbd,
  _03_cartApiService_zNjNzc6y5U,
  _03_productsService_7BAsp43aGL,
  _04_cartService_WV5Z78ltSP,
  _05_seatSelector_KIK5eMKCiR
]