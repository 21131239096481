
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_938cdVAqJ2EYMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/[...slug].vue?macro=true";
import { default as indexTCfmCbw8YxMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/index.vue?macro=true";
import { default as loginVJR6fWVPk1Meta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/login.vue?macro=true";
import { default as maintenancexfifAnDmm9Meta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/maintenance.vue?macro=true";
import { default as checkoutq7oVFCHFUBMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/checkout.vue?macro=true";
import { default as confirmationvwIZ87zy7RMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/confirmation.vue?macro=true";
import { default as _91product_93NaeNYXJDbEMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/product/[product].vue?macro=true";
import { default as _91productType_93ZraHrDrrIoMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/products/[productType].vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/[...slug].vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/login.vue")
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/maintenance.vue")
  },
  {
    name: "order-checkout___en",
    path: "/order/checkout",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/checkout.vue")
  },
  {
    name: "order-confirmation___en",
    path: "/order/confirmation",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/confirmation.vue")
  },
  {
    name: "product-product___en",
    path: "/product/:product()",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/product/[product].vue")
  },
  {
    name: "products-productType___en",
    path: "/products/:productType()",
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/products/[productType].vue")
  }
]