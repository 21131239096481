import { useRoute } from "vue-router"
import { useNuxtApp } from "#app"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()
	const { $configs } = useNuxtApp()

	const loginPageConfig = runtimeConfig.public.ENABLE_LOGIN_PAGE
	const shouldShowLoginPage = $configs.$isConfigEnabled(loginPageConfig)

	const route = useRoute()
	const loginPasswordKey = "f2ca1bb6c7e907d06dafe468".toUpperCase()
	const loginPasswordValue = "7e579fce76b37e4e93b7605022da52e6ccc26fd2".toUpperCase()

	const expectedPassword = [
		166, 180, 243, 32, 188, 93, 100, 228, 203, 105, 191, 61, 114, 12, 222, 92, 95, 13, 220, 129, 143, 117, 17, 221, 139,
		3, 212, 228, 15, 107, 100, 61,
	]

	const digestMessage = async (message) => {
		const encoder = new TextEncoder()
		const data = encoder.encode(message)
		return await crypto.subtle.digest("SHA-256", data)
	}

	const ensureSignedIn = () => {
		const loginPassword = localStorage.getItem(loginPasswordKey)
		if (loginPassword !== loginPasswordValue) {
			navigateTo({ path: "/login" })
		}
	}

	nuxtApp.provide("login", {
		$init: () => {
			if (shouldShowLoginPage) {
				watch(route, (value) => {
					if (value.path.indexOf("login") < 0) {
						ensureSignedIn()
					}
				})

				ensureSignedIn()
			}
		},

		$ensureSignedIn: ensureSignedIn,

		$login: async (password) => {
			const hash = await digestMessage(password)
			const hashArray = new Uint8Array(hash)
			const length = hash.byteLength
			for (let i = 0; i < length; i++) {
				if (hashArray[i] !== expectedPassword[i]) {
					return false
				}
			}
			localStorage.setItem(loginPasswordKey, loginPasswordValue)
			navigateTo({ path: "/products/tickets" })
			return true
		},
	})
})
